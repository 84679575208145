import React from 'react';
import Layout from '../components/Shared/Layout';
import '../assets/css/404.css';

export default () => (
  <Layout>
    <section className="c404-page-banner" />
    <div className="flex-center position-ref full-height">
      <div className="content">
        <div className="title">Sorry, the page you are looking for could not be found. </div>
      </div>
    </div>
  </Layout>
);
